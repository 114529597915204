<header class="header" id="header">
  <div class="header-container">
    <div class="logo-section">
      <div class="responsive-section" *ngIf="innerWidth < 1025">
        <div class="hamburger-container" (click)="toggleMenu()">
          <img ngSrc="assets/icon/hamburger-black.svg" alt="hamburger" width="20" height="20" loading="lazy">
        </div>
        <div class="hamburger-menu" [class.shown]="!!showMenu">
          <div class="hamburger-menu-header">
            <a [routerLink]="[ROUTE_URLS.HOME]" (click)="showMenu=false; subMenu=null;allowScrolling()">
              <img ngSrc="assets/img/logo.png" class="logo" width="96" height="28" alt="Quetal Mobile" loading="lazy">
            </a>
            <div (click)="showMenu=false;">
              <img ngSrc="assets/icon/plain-black-close-icon.svg" class="close" width="20" height="20" alt="Quetal Mobile" loading="lazy">
            </div>
          </div>
          <div class="link" *ngIf="!isLoggedIn">
            <a class="tertiary" data-cy="signInHeader" [routerLink]="[LOGIN_ROUTE_URLS.BASE+'/'+LOGIN_ROUTE_URLS.LOGIN]"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleMenu()" translate>header.sign-in</a>
          </div>
          <div class="link menu-item" *ngIf="!!isLoggedIn">
            <p class="tertiary account-header-menu " data-cy="accountMenuHeader" (click)="showSubMenu('account')" [class.menu-items-parent]="subMenu === 'account'" translate>header.account
              <img ngSrc="assets/icon/black-arrow-down.svg" alt="arrow" width="20" height="20" class="default-arrow" loading="lazy">
              <img ngSrc="assets/icon/black-arrow-up.svg" alt="arrow" width="20" height="20" class="hovered-arrow" loading="lazy"
                [class.rotate]="subMenu !== 'account'" >
            </p>
            <div class="sub-wrapper" [class.shown]="subMenu=== 'account'">
              <div class="link">
                <a class="tertiary" (click)="toggleMenu();showSubMenu(null)"
                  [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.SUMMARY]" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}" id="account-summary-header"
                  data-cy="account-summary-header" translate>header.account-summary</a>
              </div>
             
              <div class="link">
                <a class="tertiary" (click)="toggleMenu();showSubMenu(null)"
                  [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/'+ACCOUNT_ROUTE_URLS.PENDING_ACTIVATIONS]"
                  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" id="purchased-plans-header"
                  data-cy="purchased-plans-header" translate>header.purchased-plans</a>
              </div>
              <div class="link">
                <a class="tertiary" (click)="toggleMenu();showSubMenu(null)" [class.disabled]="!!isPortIn"
                  id="refill-account-header" data-cy="refill-account-header" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}"
                  [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.PAY_AND_RENEW]" translate>header.pay-and-refill</a>
              </div>
              <div class="link">
                <a class="tertiary" (click)="toggleMenu();showSubMenu(null)"
                  [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.REFER_FRIEND]"
                  id="refer-friend-header-unlogged-desktop" data-cy="refer-friend-header" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}" translate>header.refer-a-friend</a>
              </div>
              <div class="link">
                <a class="tertiary" (click)="toggleMenu();showSubMenu(null)" routerLinkActive="active"
                  [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.PLAN_ADD_ONS]"
                  [routerLinkActiveOptions]="{exact: true}" [class.disabled]="!!isExpiredAccount || !!isPortIn"
                  id="plan-addOns-header" data-cy="plan-addOns-header" translate>header.plan-addons</a>
              </div>
              <div class="link">
                <a class="tertiary" (click)="toggleMenu();showSubMenu(null)"
                  [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.ORDERS]" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}" id="your-orders-header" data-cy="your-orders-header" translate>header.your-orders</a>
              </div>
              <div class="link">
                <a class="tertiary" (click)="toggleMenu();showSubMenu(null)" [class.disabled]="!!isPortIn"
                  id="payment-history-header" data-cy="payment-history-header" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}"
                  [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.PAYMENTS]" translate>header.payment-history</a>
              </div>
              <div class="link">
                <a class="tertiary" (click)="toggleMenu();showSubMenu(null)" [class.disabled]="!!isPortIn"
                  id="usage-history-header" data-cy="usage-history-header" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}"
                  [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.USAGE]" translate>header.usage-history</a>
              </div>
              <div class="link">
                <a class="tertiary" (click)="toggleMenu();showSubMenu(null)"
                  [routerLink]=" [ACCOUNT_ROUTE_URLS.BASE + '/'+ACCOUNT_ROUTE_URLS.MANAGE_DEVICES]"
                  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" id="manage-device-header"
                  data-cy="manage-device-header" translate>header.manage-device-and-sim</a>
              </div>
              <div class="link">
                <a class="tertiary" (click)="toggleMenu();showSubMenu(null)"
                  [routerLink]=" [ACCOUNT_ROUTE_URLS.BASE + '/'+ACCOUNT_ROUTE_URLS.SETTINGS]" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}" id="profile-setting-header"
                  data-cy="profile-setting-header" translate>header.profile-settings</a>
              </div>
              <div class="link">
                <p class="tertiary" (click)="toggleMenu();showSubMenu(null)" (click)="logout($event)" id="logout-header"
                  data-cy="logout-header" translate>header.logout</p>
              </div>
            </div>
          </div>
          <div class="link menu-item">
            <p class="tertiary shop-header" data-cy="shopHeader" [class.menu-items-parent]="subMenu === 'shop'" (click)="showSubMenu('shop')" translate>header.shop
              <img ngSrc="assets/icon/black-arrow-down.svg" alt="arrow" width="20" height="20" class="default-arrow" loading="lazy">
              <img ngSrc="assets/icon/black-arrow-up.svg" alt="arrow" width="20" height="20" class="hovered-arrow" loading="lazy"
                [class.rotate]="subMenu !== 'shop'">
            </p>

            <div class="sub-wrapper" [class.shown]="subMenu === 'shop'"
              *ngIf="!isLoggedIn || (!!isLoggedIn && !userHasActivePlans)">
              <div class="link">
                <a class="tertiary" data-cy="plansHeader" (click)="toggleMenu();showSubMenu(null)"
                  [routerLink]="[SHOP_ROUTE_URLS.BASE+'/'+SHOP_ROUTE_URLS.PLANS_AND_FEATURES+'/'+PLANS_SHOP_ROUTE_URLS.NEW_PLAN]"
                  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" translate>header.plans</a>
              </div>
              <div class="link">
                <!-- <a class="tertiary" data-cy="phonesHeader" (click)="toggleMenu();showSubMenu(null)"
                  [routerLink]="[SHOP_ROUTE_URLS.BASE+'/'+PHONES_SHOP_ROUTE_URLS.BASE + '/' + PHONES_SHOP_ROUTE_URLS.TYPE]"
                  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" translate>header.phones</a> -->
              </div>
            </div>
            <div class="sub-wrapper active-plans" [class.shown]="subMenu === 'shop'"
              *ngIf="!!isLoggedIn && !!userHasActivePlans">
              <div class="user-account-part">
                <div class="title-flex" [class.bottom]="!!showMdnList" (click)="showMdnList =! showMdnList">
                  <p class="title primary" translate>header.your-account</p>
                  <img ngSrc="assets/icon/arrow-up-primary.svg" [class.rotate]="!!showMdnList" width="20" height="20"
                    alt="Arrow Icon" loading="lazy">
                </div>
                <select *ngIf="!!showMdnList" class="mdn-list" id="phonePlan" name="phonePlan"
                  [(ngModel)]="selectedPlan" (ngModelChange)="userPlanSelected($event)" [disabled]="disabled"
                  [compareWith]="planCompare">
                  <option *ngFor="let plan of userPlans" [ngValue]="plan">
                    {{getSelectorTitle(plan) | translate}}
                  </option>
                </select>
                <p class="sub-title">
                  <button class="button thirdly" aria-label="change plan" (click)="changePlan();toggleMenu();showSubMenu(null)"
                    [class.disabled]="!!isPortIn" translate>header.change-plan</button>
                </p>
                <p class="sub-title last">
                  <button class="button thirdly" aria-label="order addons" (click)="goToAddOns();toggleMenu();showSubMenu(null)"
                    [class.disabled]="!!isPortIn || !!isExpiredAccount" translate>header.order-addons</button>
                </p>
              </div>
              <div class="new-account-part">
                <p class="title primary" translate>header.new-account</p>
                <p class="sub-title last">
                  <button class="button thirdly" aria-label="purchase new plan" (click)="toggleMenu();showSubMenu(null)" data-cy="plansHeader"
                    [routerLink]="[SHOP_ROUTE_URLS.BASE+'/'+SHOP_ROUTE_URLS.PLANS_AND_FEATURES+'/'+PLANS_SHOP_ROUTE_URLS.NEW_PLAN]" translate>header.purchase-new-plan</button>
                </p>
                <!-- <p class="sub-title last">
                  <button class="button thirdly" aria-label="purchase phone" (click)="toggleMenu();showSubMenu(null)" data-cy="phonesHeader"
                    [routerLink]="[SHOP_ROUTE_URLS.BASE+'/'+PHONES_SHOP_ROUTE_URLS.BASE + '/' + PHONES_SHOP_ROUTE_URLS.TYPE]" translate>header.purchase-phone</button>
                </p> -->
              </div>
            </div>
          </div>
          <div class="link">
            <a class="tertiary" id="check-your-phone-header" data-cy="check-your-phone-header" (click)="toggleMenu()"
              [routerLink]="[ROUTE_URLS.BRING_PHONE]" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}" translate>header.bring-your-phone</a>
          </div>
          <div class="link">
            <a class="tertiary" id="coverage-header" data-cy="coverage-header" (click)="toggleMenu()"
              [routerLink]="[SUPPORT_ROUTE_URLS.BASE+'/'+SUPPORT_ROUTE_URLS.LANDING_COVERAGE]" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}" translate>header.coverage</a>
          </div>
          <div class="link">
            <a class="tertiary" id="activate" data-cy="activate" (click)="toggleMenu()"
              [routerLink]="[ACTIVATION_ROUTE_URLS.BASE+'/'+ACTIVATION_ROUTE_URLS.SIM_CHECK]" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}" translate>header.activate</a>
          </div>
        </div>
      </div>
      <a [routerLink]="[ROUTE_URLS.HOME]" (click)="showMenu=false; subMenu=null;allowScrolling()">
        <img ngSrc="assets/img/logo.png" class="logo" width="96" height="28" alt="Quetal Mobile" loading="lazy">
      </a>
    </div>
    <div class="header-links" *ngIf="innerWidth > 1024.9">
      <div class="link menu-item">
        <p class="tertiary shop-header" data-cy="shopHeader" translate>header.shop
          <img ngSrc="assets/icon/black-arrow-down.svg" alt="arrow" width="20" height="20" class="default-arrow" loading="lazy">
          <img ngSrc="assets/icon/primary-arrow-up.svg" alt="arrow" width="20" height="20" class="hovered-arrow" loading="lazy">
        </p>
        <div class="wrapper" *ngIf="!isLoggedIn || (!!isLoggedIn && !userHasActivePlans)">
          <div class="link">
            <a class="tertiary" data-cy="plansHeader"
              [routerLink]="[SHOP_ROUTE_URLS.BASE+'/'+SHOP_ROUTE_URLS.PLANS_AND_FEATURES+'/'+PLANS_SHOP_ROUTE_URLS.NEW_PLAN]"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" translate>header.plans</a>
          </div>
          <!-- <div class="link">
            <a class="tertiary" data-cy="phonesHeader"
              [routerLink]="[SHOP_ROUTE_URLS.BASE+'/'+PHONES_SHOP_ROUTE_URLS.BASE + '/' + PHONES_SHOP_ROUTE_URLS.TYPE]"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" translate>header.phones</a>
          </div> -->
        </div>
        <div class="wrapper active-plans" *ngIf="!!isLoggedIn && !!userHasActivePlans">
          <div class="user-account-part">
            <div class="mdn-cell">
              <p class="title primary" translate>header.your-account</p>
              <select class="mdn-list" id="phonePlan" name="phonePlan" [(ngModel)]="selectedPlan"
                (ngModelChange)="userPlanSelected($event)" [disabled]="disabled" [compareWith]="planCompare">
                <option *ngFor="let plan of userPlans" [ngValue]="plan">
                  {{getSelectorTitle(plan)}}
                </option>
              </select>
            </div>
            <p class="sub-title">
              <button class="button thirdly" aria-label="change plan" data-cy="changePlanHeader" (click)="changePlan()"
                [class.disabled]="!!isPortIn" translate>header.change-plan</button>
            </p>
            <p class="sub-title last">
              <button class="button thirdly" aria-label="order addons" data-cy="orderAddOnsHeader" (click)="goToAddOns()"
                [class.disabled]="!!isPortIn || !!isExpiredAccount" translate>header.order-addons</button>
            </p>
          </div>
          <div class="border"></div>
          <div class="new-account-part">
            <p class="title primary" translate>header.new-account</p>
            <p class="sub-title last">
              <button class="button thirdly" aria-label="purchase new plan" data-cy="plansHeader"
                [routerLink]="[SHOP_ROUTE_URLS.BASE+'/'+SHOP_ROUTE_URLS.PLANS_AND_FEATURES+'/'+PLANS_SHOP_ROUTE_URLS.NEW_PLAN]"
                translate>header.purchase-new-plan</button>
            </p>
            <!-- <p class="sub-title last">
              <button class="button thirdly" aria-label="purchase phone" data-cy="phonesHeader"
                [routerLink]="[SHOP_ROUTE_URLS.BASE+'/'+PHONES_SHOP_ROUTE_URLS.BASE + '/' + PHONES_SHOP_ROUTE_URLS.TYPE]"
                translate>header.purchase-phone</button>
            </p> -->
          </div>
        </div>
      </div>
      <div class="link">
        <a class="tertiary" id="check-your-phone-header" data-cy="check-your-phone-header"
          [routerLink]="[ROUTE_URLS.BRING_PHONE]" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}" translate>header.bring-your-phone</a>
      </div>
      <div class="link">
        <a class="tertiary" id="coverage-header" data-cy="coverage-header"
          [routerLink]="[SUPPORT_ROUTE_URLS.BASE+'/'+SUPPORT_ROUTE_URLS.LANDING_COVERAGE]" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}" translate>header.coverage</a>
      </div>
      <div class="link">
        <a class="tertiary" id="activate" data-cy="activate"
          [routerLink]="[ACTIVATION_ROUTE_URLS.BASE+'/'+ACTIVATION_ROUTE_URLS.SIM_CHECK]" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}" translate>header.activate</a>
      </div>
    </div>
    <div class="login-icons-links">
      <div class="link" *ngIf="!isLoggedIn && innerWidth > 1024.9">
        <a class="tertiary" [routerLink]="[LOGIN_ROUTE_URLS.BASE+'/'+LOGIN_ROUTE_URLS.LOGIN]" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}" translate>header.sign-in</a>
      </div>
      <div class="link menu-item" *ngIf="!!isLoggedIn && innerWidth > 1024.9">
        <p class="tertiary account-header-menu" data-cy="accountMenuHeader">
          <img ngSrc="assets/icon/user-icon.svg" alt="user icon" width="24" height="24" class="default-user-icon" loading="lazy">
          <img ngSrc="assets/icon/primary-user-icon.svg" alt="user icon" width="24" height="24" class="hovered-user-icon" loading="lazy">
          <img ngSrc="assets/icon/black-arrow-down.svg" alt="arrow" width="20" height="20" class="default-arrow" loading="lazy">
          <img ngSrc="assets/icon/primary-arrow-up.svg" alt="arrow" width="20" height="20" class="hovered-arrow" loading="lazy">
        </p>
        <div class="wrapper account">
          <div class="link">
            <a class="tertiary" [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.SUMMARY]"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" id="account-summary-header"
              data-cy="account-summary-header" translate>header.account-summary</a>
          </div>
          <div class="link">
            <a class="tertiary" [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/'+ACCOUNT_ROUTE_URLS.PENDING_ACTIVATIONS]"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" id="purchased-plans-header"
              data-cy="purchased-plans-header" translate>header.purchased-plans</a>
          </div>
          <div class="link">
            <a class="tertiary" [class.disabled]="!!isPortIn" id="refill-account-header" data-cy="refill-account-header"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
              [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.PAY_AND_RENEW]" translate>header.pay-and-refill</a>
          </div>
          <div class="link">
            <a class="tertiary" [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.REFER_FRIEND]"
              id="refer-friend-header-unlogged-desktop" data-cy="refer-friend-header" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}" translate>header.refer-a-friend</a>
          </div>
          <div class="link">
            <a class="tertiary" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
              [class.disabled]="!!isExpiredAccount || !!isPortIn"
              [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.PLAN_ADD_ONS]" id="plan-addOns-header"
              data-cy="plan-addOns-header" translate>header.plan-addons</a>
          </div>
          <div class="link">
            <a class="tertiary" [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.ORDERS]"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" id="your-orders-header" translate>header.your-orders</a>
          </div>
          <div class="link">
            <a class="tertiary" [class.disabled]="!!isPortIn" id="payment-history-header"
              data-cy="payment-history-header" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
              [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.PAYMENTS]" translate>header.payment-history</a>
          </div>
          <div class="link">
            <a class="tertiary" [class.disabled]="!!isPortIn" id="usage-history-header" data-cy="usage-history-header"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
              [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.USAGE]" translate>header.usage-history</a>
          </div>
          <div class="link">
            <a class="tertiary" [routerLink]=" [ACCOUNT_ROUTE_URLS.BASE + '/'+ACCOUNT_ROUTE_URLS.MANAGE_DEVICES]"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" id="manage-device-header"
              data-cy="manage-device-header" translate>header.manage-device-and-sim</a>
          </div>
          <div class="link">
            <a class="tertiary" [routerLink]=" [ACCOUNT_ROUTE_URLS.BASE + '/'+ACCOUNT_ROUTE_URLS.SETTINGS]"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" id="profile-setting-header"
              data-cy="profile-setting-header" translate>header.profile-settings</a>
          </div>
          <div class="link">
            <p class="tertiary" (click)="logout($event)" id="logout-header" data-cy="logout-header" translate>header.logout</p>
          </div>
        </div>
      </div>
      <div class="border" *ngIf="innerWidth > 1024.9"></div>
      <div class="icon-link">
        <img width="24" height="24" ngSrc="assets/icon/cart black.svg" alt="cart icon" (click)="goToCart()" loading="lazy">
        <span class="counter" *ngIf="hasCartItem && totalItems !== 0">{{totalItems}}</span>
      </div>
      <div class="icon-link">
        <img width="24" height="24" ngSrc="assets/icon/bell black.svg" alt="notification icon" (click)="getPriority()" loading="lazy">
        <span class="counter" *ngIf="!!isLoggedIn && notificationCounter > 0">{{notificationCounter}}</span>
      </div>
      <div class="link menu-item">
        <div class="tertiary lang-section" (click)="showLang()" *ngIf="innerWidth >= 640"> <img class="lang-logo"
            width="24" height="24" loading="lazy"
            [ngSrc]="selectedLanguage == 'es' ? '/assets/icon/espanol-flag.svg' : '/assets/icon/english-flag.svg'"
            alt="flag"> <p>{{selectedLanguage == 'es' ? 'ES' : 'EN'}}</p>
          <img ngSrc="assets/icon/black-arrow-down.svg" alt="arrow" width="20" height="20" class="default-arrow" loading="lazy">
          <img ngSrc="assets/icon/primary-arrow-up.svg" alt="arrow" width="20" height="20" class="hovered-arrow" loading="lazy">
        </div>
        <div class="tertiary lang-section" (click)="showLang()" *ngIf="innerWidth < 640"> 
          <img class="lang-logo" loading="lazy"
            width="24" height="24"
            [ngSrc]="selectedLanguage == 'es' ? '/assets/icon/espanol-flag.svg' : '/assets/icon/english-flag.svg'"
            alt="flag">
          <img ngSrc="assets/icon/black-arrow-down.svg" alt="arrow" width="20" height="20" class="default-arrow" loading="lazy">
          <img ngSrc="assets/icon/primary-arrow-up.svg" alt="arrow" width="20" height="20" class="hovered-arrow" loading="lazy">
        </div>
        <div class="wrapper" [class.shown]="!!showLangSection">

          <div class="link lang-link" [class.selected]="selectedLanguage === 'en'">
            <div class="tertiary" (click)="translateLanguage('en')">
              <img class="lang-logo" width="24"
                height="24"
                loading="lazy"
                ngSrc="/assets/icon/english-flag.svg"
                alt="flag" alt="flag">
                <p>English</p> 
            </div>
               <img ngSrc="/assets/icon/red-check.svg" alt="check"
               width="20" height="20" loading="lazy" *ngIf="selectedLanguage === 'en'">
          </div>
          <p class="custom-br"></p>
          <div class="link lang-link" [class.selected]="selectedLanguage === 'es'">
            <div class="tertiary" (click)="translateLanguage('es')"><img class="lang-logo" loading="lazy" width="24"
                height="24"
                ngSrc="/assets/icon/espanol-flag.svg"
                alt="flag" alt="flag">
                <p>Español </p></div>
                <img ngSrc="/assets/icon/red-check.svg" alt="check"
                width="20" height="20" loading="lazy" *ngIf="selectedLanguage === 'es'">
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="alert-banner" *ngIf="!!mantainenceDescription && !!displayMaintenanceBanner && !showOutage">
  <img class="alert-icon" ngSrc="assets/icon/alert.svg" alt="alert" width="24" height="24" loading="lazy">
  <div class="message" [innerHTML]="mantainenceDescription"></div>
</div>
<div class="ios-upgrade-banner" *ngIf="!!isLoggedIn && !isCloseBanner && !isOnIosLandingPage && !showOutage">
  <div class="flex">
    <div class="flex-title">
      <img ngSrc="assets/icon/header-warning-icon.svg" class="warning-icon" width="28" height="28" alt="Warning Icon" loading="lazy">
      <div class="banner-content">
        <p class="smaller" translate><b translate>ios-upgrade-banner.ios-info<button class="button tertiary view-action"
              (click)="isMoreDetails =! isMoreDetails">
              {{ 'ios-upgrade-banner.view' | translate: { val: getToggleText() | translate} }}</button></b></p>
        <ng-container *ngIf="!!isMoreDetails">
          <p class="smaller" translate><span translate>ios-upgrade-banner.iPhone</span> <b translate>ios-upgrade-banner.ios-version</b> <span translate>ios-upgrade-banner.is-now</span> <b translate>ios-upgrade-banner.available</b> <span translate>ios-upgrade-banner.for</span> <b translate>ios-upgrade-banner.iphone-xr</b> <span translate>ios-upgrade-banner.and</span> <b translate>ios-upgrade-banner.up</b>.
            <span translate>ios-upgrade-banner.users-who-have-taken</span> <b translate>ios-upgrade-banner.change</b> <span translate>ios-upgrade-banner.a</span> <b translate>ios-upgrade-banner.setting</b> <span translate>ios-upgrade-banner.on-the-phone-which-would</span>
            <b translate>ios-upgrade-banner.enable-mms</b>.
          </p>
          <p class="smaller last" translate>ios-upgrade-banner.for-more-info <a
              [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.IOS17]"
              (click)="isMoreDetails = false" translate>ios-upgrade-banner.ios-17-insturctions-page</a></p>
        </ng-container>
      </div>
    </div>
    <img ngSrc="assets/icon/plain-primary-close-icon.svg" (click)="closeBanner()" class="close-icon" width="24"
      height="24" alt="Warning Icon" loading="lazy">
  </div>
</div>
<div class="outage-banner" *ngIf="!!showOutage">
  <div class="flex">
      <img ngSrc="assets/icon/banner-alert-icon.svg" class="alert-icon" width="24" height="24" alt="alert Icon" loading="lazy">
      <div class="banner-content">
        <p class="smaller" translate><b translate>outage-banner.network-issue</b></p>
       <p class="smaller" translate>outage-banner.restore-service</p>
      </div>
  </div>
</div>
